import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BootstrapVueNext from 'bootstrap-vue-next'

import vClickOutside from 'click-outside-vue3'
import VueApexCharts from 'vue3-apexcharts'
import { vMaska } from 'maska'
import VueFeather from 'vue-feather'

import '@/assets/scss/config/material/app.scss'
import '@/assets/scss/mermaid.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'

createApp(App)
  .use(router)
  .use(store)
  .use(BootstrapVueNext)
  .use(VueApexCharts)
  .component(VueFeather.type, VueFeather)
  .directive('maska', vMaska)
  .use(vClickOutside)
  .mount('#app')
