import store from '../store'
export default [
  {
    path: '/',
    name: 'default',
    meta: {
      title: 'Login',
      beforeResolve (routeTo, routeFrom, next) {
        if (store.getters.loggedIn) {
          next({ name: 'cashier' })
          next()
        } else {
          next()
        }
      }
    },
    component: () => import('../views/auth/LoginView.vue')
  },
  {
    path: '/cashier/:id',
    name: 'cashier',
    meta: {
      title: 'Cashier',
      authRequired: true
    },
    component: () => import('../views/cashier/index.vue')
  },
  {
    path: '/inventory/:id',
    name: 'inventory',
    meta: {
      title: 'inventory',
      authRequired: true
    },
    component: () => import('../views/inventory/index.vue')
  },
  {
    path: '/requests',
    name: 'requests',
    meta: {
      title: 'requests',
      authRequired: true
    },
    component: () => import('../views/requests/index.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/auth/LoginView.vue')
  }
]
